<template>
  <div>
    <van-cell
      is-link
      v-for="(item, index) in contents"
      :index="index"
      :key="index"
      @click="click(item)"
      class="wzlb-list-item"
    >
      <template #title>
        <div class="wzlb-list-item-title">{{ item.title }}</div>
      </template>
    </van-cell>
  </div>
</template>

<script>
export default {
  name: "recruitList",
  data() {
    return {
      recruitList: "",
      contents: [],
    };
  },
  methods: {
    getData() {
      let postData = {
        parentId: this.$route.query.id,
      };
      this.$http
        .get("/api/content/infoByParentId", postData)
        .then((res) => {
          this.recruitList = res.data.data.find(
            (item) => item.title == "人才招聘"
          );
        })
        .then(() => {
          let param = {
            parentId: this.recruitList.id,
          };
          this.$http
            .get("/api/content/infoByParentId", param)
            .then((result) => {
              this.contents = result.data.contents;
            });
        });
    },
    click(item) {
      window.sessionStorage.setItem("currentArticle", JSON.stringify(item));
      this.$router.push({
        path: "/recruitDetail",
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.wzlb-list-item {
  text-align: left;
}
.wzlb-list-item-title {
  width: 8.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
</style>
